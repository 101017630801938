<template>
  <div class="user">
    <img class="tabImg" src="@/assets/img/homeSeventh/myDataBg.png" />
    <div class="conmain">
      <div class="nav_center">
        <!-- /* 用户信息 */ -->
        <div class="info">
          <img class="userImg" :src="detail.img" alt="" />
          <div class="nickName">{{ detail.nickName }}</div>
        </div>
        <!-- 导航菜单 -->
        <div class="mymenu">
          <div
            class="mymenu-item"
            :class="[tabId == item.url ? 'on' : '']"
            @click.stop="tabChange(item)"
            v-for="(item, index) in list"
            :key="index"
          >
            <i class="iconfont" :class="[item.icon]" />{{ item.title }}
          </div>
        </div>
      </div>

      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo } from "@/api/cookies";
import { getUserCourseStatistics } from "@/api/user";
export default {
  components: {},

  data() {
    return {
      m: "",
      tel: "",
      uid: "",
      userId: "",
      list: [
        {
          id: 2,
          title: "个人资料",
          url: "/seventhPage/my/myInfo",
          icon: "icon-bianjigerenziliao",
        },
        {
          id: 3,
          title: "我的订单",
          url: "/seventhPage/my/mineOrder",
          icon: "icon-a-Group1530",
        },
        {
          id: 4,
          title: "优惠券",
          url: "/seventhPage/my/mineCoupon",
          icon: "icon-youhuiquan1",
        },
        {
          id: 6,
          title: "兑换券",
          url: "/seventhPage/my/mineExchange",
          icon: "icon-youhuiquan1",
        },
        {
          id: 9,
          title: "学习卡",
          url: "/seventhPage/my/myCard",
          icon: "icon-xuexika",
        },
        {
          id: 7,
          title: "意见反馈",
          url: "/seventhPage/my/feedback",
          icon: "icon-yijianfankui1",
        },
        {
          id: 8,
          title: "账户设置",
          url: "/seventhPage/my/accountSetting",
          icon: "icon-zhanghushezhi",
        },
        {
          id: 5,
          title: "我的消息",
          url: "/seventhPage/my/mineNews",
          icon: "icon-a-Group1533",
        },
        {
          id: 10,
          title: "我的收藏",
          url: "/seventhPage/my/collect",
          icon: "icon-shoucangxing",
        },
      ],
      detail: {
        img: "",
        nickName: "",
      },
      tabId: 1,
      chong: {},
    };
  },
  watch: {
    //使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    $route: function (newVal, oldVal) {
      if (oldVal.path == "/login" || newVal.path == "/login") {
        this.userId = localStorage.getItem("userId");
      }
    },
  },
  created() {
    this.userId = localStorage.getItem("userId");
    if (!this.userId) {
      Vue.prototype.goLoginView("mustBack");
      return;
    }
    this.tabId = this.$route.path;

    this.detail = getInfo();
    // 个人中心 顶部数据
    getUserCourseStatistics(this.userId).then((res) => {
      this.chong = res.msg;
    });

    //滚动条监控  答题卡 滚动到顶部悬浮
    $(document).scroll(function () {
      // 滚动到上面  隐藏
      $(window).scroll(function () {
        let top = $(window).scrollTop();
        if (top >= 290) {
          $(".userCentLeft").addClass("fixed-card");
          $(".zhicheng").removeClass("dis-none");
        } else {
          $(".userCentLeft").removeClass("fixed-card");
          $(".zhicheng").addClass("dis-none");
        }
      });
    });
  },
  methods: {
    tabChange(val) {
      if (this.tabId == val.url) {
        return;
      }
      this.titles = val.title;
      this.tabId = val.url;
      this.$router.replace(val.url);
    },
  },
};
</script>
<style lang="less" scoped>
.area-selected-trigger {
  width: 300px;
  height: 40px;
  line-height: 20px;
}
.el-input.el-input__inner {
  width: 100px;
}
.user {
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  .tabImg {
    width: 100%;
    height: 80px;
  }
  .conmain {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }
  .nav_center {
    width: 280px;
    height: max-content;
    background: #ffffff;
    padding: 22px 16px;
    margin-right: 20px;
    flex-shrink: 0;
    /* 用户信息 */
    .info {
      padding: 0px 0px 20px;
      border-bottom: 1px solid #eeeeee;
      .userImg {
        width: 80px;
        height: 80px;
        opacity: 1;
        border: 2px solid #ffffff;
        border-radius: 50%;
        margin: auto;
      }
      .nickName {
        height: 18px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        text-align: center;
        margin-top: 16px;
      }
    }
    /* 导航菜单 */
    .mymenu {
      margin-top: 16px;
      .mymenu-item {
        width: 100%;
        height: 48px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 48px;
        text-align: center;
        display: flex;
        padding-left: 24px;
        cursor: pointer;
        .iconfont {
          flex-shrink: 0;
          font-size: 16px;
          margin-right: 16px;
          color: #4a6af0;
        }
      }
      .on {
        background: #4a6af0;
        color: #ffffff;
        .iconfont {
          color: #ffffff;
        }
      }
    }
  }
}
.zhicheng {
  width: 180px;
  height: 100%;
  margin-right: 20px;
  flex-shrink: 0;
}
.ccc {
  color: #1061ff;
}
.content {
  width: 1084px;
  // margin-bottom: 30px;
  // padding-bottom: 30px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
}
</style>
